import ReactGA from "react-ga4";

type Props = {
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: "beacon" | "xhr" | "image";
};

export function eventGa({
  action,
  label,
  nonInteraction,
  transport,
  value,
}: Props) {
  const isGooxxer = Boolean(localStorage.getItem("isGooxxer"));
  console.log(isGooxxer);
  if (!isGooxxer) {
    ReactGA.event({
      category: "External",
      action,
      label,
      value,
      nonInteraction,
      transport,
    });
  }
}
