import { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { listResults } from "../../services/results.service";
import { ReportsCards } from "./components/ReportsCards";
import { ReportsSkeleton } from "./components/ReportsSkeleton";
import { eventGa } from "../../utils/eventClickGa";
import { useTimeout } from "usehooks-ts";
import { t } from "i18next";
import ReactGA from "react-ga4";

export function Relatorios() {
  const [reports, setReports] = useState<Array<PortalReport>>();
  const [kinds, setKinds] = useState<Set<ReportKind>>();
  const [isLoading, setIsLoading] = useState(true);
  const [supplier, setSupplier] = useState<string>(
    localStorage.getItem("gaClient") || "Not defined"
  );

  useEffect(() => {
    document.title = t("Reports.reports") as string;

    const request = async () => {
      setIsLoading(true);
      try {
        const { data } = await listResults();
        setReports(data.externalPanelReports);
        setKinds(
          new Set(
            data.externalPanelReports.map((item: PortalReport) => item.kind)
          )
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    request();
  }, []);

  useTimeout(() => {
    setSupplier(localStorage.getItem("gaClient") || "Not defined");
  }, 2000);

  useTimeout(() => {
    if (ReactGA.isInitialized) {
      ReactGA.event({
        label: supplier,
        category: `Reports - ${supplier}`,
        action: "Acessos Externos",
      });
    }
  }, 3000);

  return (
    <Fragment>
      <Container>
        {!isLoading && reports && kinds ? (
          [...kinds].map((item, index) => (
            <Fragment key={item + index}>
              <ReportsCards
                name={item}
                files={reports.filter((report) => report.kind === item)}
              />
            </Fragment>
          ))
        ) : (
          <ReportsSkeleton />
        )}
      </Container>
    </Fragment>
  );
}
