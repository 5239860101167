import { BrowserRouter } from "react-router-dom";
import { Routers } from "./routers";
import { RecoilRoot } from "recoil";
import { ToastContainer } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";
import "../src/scss/global.scss";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";

import { useState } from "react";
import { ExternalKeyProvider } from "./context/CustomerExternalKeyContext";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";

const production = import.meta.env.VITE_AMBIENTE === "production";

setTimeout(() => {
  const isGooxxer = Boolean(localStorage.getItem("isGooxxer"));

  if (!isGooxxer && production) {
    ReactGA.initialize(import.meta.env.VITE_GA_TRACKING);
  }
}, 2000);

export function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ExternalKeyProvider>
        <BrowserRouter>
          <RecoilRoot>
            <Routers />
            <ToastContainer />
          </RecoilRoot>
        </BrowserRouter>
      </ExternalKeyProvider>
    </I18nextProvider>
  );
}
