import { useState, useEffect } from "react";
import WeightMap from "./components/WeightMap";
import {
  listWeightMapData,
  getMapGeoJson,
} from "../../../services/invoice.service";
import { FeatureCollection } from "geojson";
import { MapDataObject } from "./maps";
import { Row, Col, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Sidebar } from "../../../components/Sidebar";
import { SidebarButton } from "../../../components/Sidebar/SidebarButton";
import MonthFilter from "../../../components/MonthFilter";
import { t } from "i18next";
import { BsFillInfoCircleFill } from "react-icons/bs";

export default function Maps() {
  const [loadingMap, setLoadingMap] = useState<boolean>(false);
  const [mapData, setMapData] = useState<MapDataObject | null>(null);
  const [mapGeoJson, setMapGeoJson] = useState<FeatureCollection | null>(null);
  const [openMonth, setOpenMonth] = useState(false);
  const [monthFilter, setMonthFilter] = useState<{
    startAt: string;
    endAt: string;
  } | null>(null);

  const fetchMapData = async () => {
    setLoadingMap(true);
    try {
      let response: MapDataObject;

      if (monthFilter) {
        response = await listWeightMapData(monthFilter);
      } else {
        response = await listWeightMapData({});
      }

      setMapData(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMap(false);
    }
  };

  const fetchMapGeoJson = async () => {
    setLoadingMap(true);
    try {
      const response = await getMapGeoJson();
      setMapGeoJson(response as FeatureCollection);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMap(false);
    }
  };

  useEffect(() => {
    updateData();
  }, [monthFilter]);

  useEffect(() => {
    fetchMapGeoJson();
  }, []);

  const handleDateRangeChange = (startAt: string, endAt: string) => {
    setMonthFilter(startAt && endAt ? { startAt, endAt } : null);
  };

  const updateData = async () => {
    await fetchMapData();
  };

  return (
    <Container fluid className="vh-100 d-flex flex-column">
      <Row className="bg-white flex-grow-1 d-flex">
        <Col xs="2" className="d-flex flex-column">
          <Sidebar>
            <SidebarButton
              text="Mes/Ano"
              open={setOpenMonth}
              view={openMonth}
            />
            {openMonth && (
              <MonthFilter
                selectedMonth={4}
                selectedYear={2024}
                setDateRange={handleDateRangeChange}
                useDateRange={true}
              />
            )}
          </Sidebar>
        </Col>
        <Col className="justify-content-center align-items-center">
          {" "}
          <div className="d-flex gap-2 mt-4">
            <h4>{t("Maps.attributes.mapsDestiny")}</h4>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip>
                  {t("Maps.attributes.destinyRecolocationsInfo")}
                </Tooltip>
              }
            >
              <span>
                <BsFillInfoCircleFill className="text-secondary" />
              </span>
            </OverlayTrigger>
          </div>
          <WeightMap
            width={600}
            height={600}
            scale={750}
            data={mapGeoJson as FeatureCollection}
            numData={mapData}
            loading={loadingMap}
          />
        </Col>
      </Row>
    </Container>
  );
}
